<template>
	<section class="head">
		<div class="container">
			<div class="head-inner">
				<h1>{{title}}</h1>
				<div v-if="showSettingsButton" class="head-bnts">
					<button class="btn btn-outline-secondary d-flex align-items-center " @click="onSettingsClick"><i class="svg-image-settings"></i>{{buttonName}}</button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
    export default {
        name: "PageHeader",
        props: {
            title: {
                type: String,
				required: true
			},
			showSettingsButton : {
                type: Boolean,
                required: false,
				default: false
			},
            onSettingsClick: {
                type: Function,
				required: false,
				default: () => {}
            },
            buttonName: {
                type: String,
                required: false,
				default: 'Settings'
			}
		}
    }
</script>


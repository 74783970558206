<template>
	<div class="sf-cnt">
		<div class="sf-cnt-top">
			<div class="sf-cnt-top__ellipses">
				<div class="sf-cnt-top__ellipse"></div>
				<div class="sf-cnt-top__ellipse"></div>
				<div class="sf-cnt-top__ellipse"></div>
			</div>
		</div>
		<div class="sf-cnt-main sf-cnt-main--page sf-cnt-preview">
			<ReportContent :settings="reportSettings" :data="data"
						   @refresh="refreshReport">
			</ReportContent>
		</div>
	</div>

</template>

<script>
    import ReportContent from "../../../../../shared/components/ReportContent";
    import {REPORT_SAMPLE} from "../../../../../shared/constants/reportExample"
    import moment from "moment";
    export default {
        name: "ReportPreview",
        components: {ReportContent},
		props: {
            reportSettings : {
                type: Object,
			}
		},
		data() {
            return {
                data : REPORT_SAMPLE
			}
		},
		created() {
            this.data.updatedTime = moment().format('LLL');
            // this.reportSettings.view_analytics = 0;
        },
        methods: {
            refreshReport($event) {
                $event.preventDefault();
			}
		}
    }
</script>

<template>
	<div class="sf-cnt-inner" :style="{fontFamily: settings.custom_font + ' !important'}">
		<div class="sf-cnt-page">
			<div class="sf-cnt-header">
				<div class="sf-cnt-header__logo"><img :src="settings.logo_path" alt=""></div>
				<div class="sf-cnt-header__contact">
					<p v-if="settings.company_name">{{getLabel('Prepared by')}}: <a>{{settings.company_name}}</a></p>
					<p v-if="settings.company_phone"><a :href="`tel:${settings.company_phone}`">{{settings.company_phone}}</a>
					</p>
					<p v-if="settings.company_email"><a class="blue-link" :href="`mailto:${settings.company_email}`">{{settings.company_email}}</a>
					</p>
				</div>
			</div>
			<div class="sf-cnt-title-wrap">
				<h2 class="sf-cnt-title">{{getLabel('Single Page Audit Report')}}</h2>
				<p  class="sf-cnt-subtitle">
					<span v-if="settings.show_powered_by">{{getLabel('Powered by')}}: <a href="https://siteauditor.com">SiteAuditor.com</a></span>
				</p>
			</div>
			<div class="sf-cnt-hero">
				<div class="sf-cnt-hero__item">
					<div class="sf-cnt-hero__img">
						<div class="sf-cnt-hero__img-laptop">
							<img class="sf-cnt-hero__img-laptop__frame" src="../../shared/assets/styles/images/pc.svg"
								 alt="Desktop Screenshot">
							<img class="sf-cnt-hero__img-laptop__screen" :src="data.desktopScreenshot" alt="">
						</div>
						<div class="sf-cnt-hero__img-mobile" v-if="data.mobileScreenshot">
							<img class="sf-cnt-hero__img-mobile__frame"
								 src="../../shared/assets/styles/images/phone-sc.svg" alt="Mobile Screenshot">
							<img class="sf-cnt-hero__img-mobile__screen" :src="data.mobileScreenshot" alt="">
						</div>
					</div>
				</div>
				<div class="sf-cnt-hero__item">
					<div class="sf-cnt-hero__info">
						<div class="sf-cnt-hero__info-progress">
							<div class="progress-circle"  id="scoreBar">

							</div>

						</div>
						<div class="sf-cnt-hero__info-txt">{{getLabel('Webpage')}}:
							<a class="green-link" :href="data.website" target="_blank">{{data.website}}</a>
						</div>
						<div class="sf-cnt-hero__info-refresh"><span>{{getLabel('Audited on')}}: </span>
							{{data.updatedTime}}
							<a ><i class="icon-refresh" @click="$emit('refresh')"></i></a>
						</div>
						<div class="sf-cnt-hero__info-cards sf-cnt-cards">
							<div class="card">
								<div class="card-num card-num--red">{{data.issuesFound}}</div>
								<div class="card-text">{{getLabel('Issues found')}}:</div>
							</div>
							<div class="card">
								<div v-if="data.overAllScore" class="card-num card-num--green">
									{{data.overAllScore.toFixed(0)}}
								</div>
								<div class="card-text">{{getLabel('Overall score')}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<template v-if="showFirstPageOnly">
				<div class="d-flex justify-content-center sf-cnt-message">
					<h2>{{settings.score_response_message}}</h2>
				</div>

			</template>

			<template v-else>

				<div class="sf-cnt-section" v-if="settings.view_options.desktop">
					<h2 class="sf-cnt-section__title">{{getLabel('Desktop Analysis')}}</h2>
					<template v-if="data.desktopAnalysis.loading">
						<Loading/>
					</template>
					<template v-else>
						<p>
							<a class="sf-cnt-section__subtitle cursor-pointer" v-b-toggle.desktopAnalysis>
								<span class="btn-txt">{{getLabel('Web Vitals')}}</span>
								<span class="btn-icon"><i class="icon-arrow-down"></i></span></a>
						</p>
						<div class="card card-body sf-cnt-section__cnt">
							<div class="sf-cnt-section__cards sf-cnt-cards card-group">
								<div class="card">
									<div class="card-text card-text--small card-text--dark">
										{{getLabel('Large Contentful Paint')}}
									</div>
									<div :class="`card-num card-num--${rateClass(data.desktopAnalysis.largeContentfulPaint.rate)}`">
										<template v-if="data.desktopAnalysis.largeContentfulPaint.score !== null">
											{{data.desktopAnalysis.largeContentfulPaint.score.toFixed(1)}}
										</template>
									</div>
									<div class="card-text card-text--small">{{getLabel('Seconds')}}</div>
								</div>
								<div class="card">
									<div class="card-text card-text--small card-text--dark">
										{{getLabel('Cumulative Layout Shift')}}
									</div>
									<div :class="`card-num card-num--${rateClass(data.desktopAnalysis.cumulativeLayoutShift.rate)}`">
										<template v-if="data.desktopAnalysis.cumulativeLayoutShift.score !== null">
											{{data.desktopAnalysis.cumulativeLayoutShift.score}}
										</template>
									</div>
								</div>
							</div>
							<div class="sf-cnt-section__list">
								<div v-if="data.desktopAnalysis.largeContentfulPaint.message && data.desktopAnalysis.largeContentfulPaint.score"
									 class="sf-cnt-section__list-item"
									 :class="`sf-cnt-section__list-item--${rateClass(data.desktopAnalysis.largeContentfulPaint.rate)}`">
									<div class="sf-cnt-section__list-txt">
										{{getShortRecommendation('LargeContentfulPaint',
										data.desktopAnalysis.largeContentfulPaint.message,
										data.desktopAnalysis.largeContentfulPaint.rate)}}
									</div>
								</div>
								<div v-if="data.desktopAnalysis.cumulativeLayoutShift.message && data.desktopAnalysis.cumulativeLayoutShift.score !== null"
									 class="sf-cnt-section__list-item"
									 :class="`sf-cnt-section__list-item--${rateClass(data.desktopAnalysis.cumulativeLayoutShift.rate)}`">
									<div class="sf-cnt-section__list-txt">
										{{getShortRecommendation('CumulativeLayoutShift',
										data.desktopAnalysis.cumulativeLayoutShift.message,
										data.desktopAnalysis.cumulativeLayoutShift.rate)}}
									</div>
								</div>
							</div>
						</div>
						<b-collapse id="desktopAnalysis" class="sf-cnt-section__info">
							<div class="alert alert-light" role="alert">
								<template v-if="shouldUseRecommendation('web_vitals')">
									{{getRecommendation('web_vitals')}}
								</template>
								<template v-else>
									<p>Web Vitals is an initiative by Google to provide unified guidance
										for quality signals that are essential to delivering a great
										user experience on the web.</p>
									<p><strong>Largest Contentful Paint (LCP):</strong> measures loading
										performance. LCP marks the time at which the largest text or
										image is painted. To provide a good user experience, LCP should
										occur within 2.5 seconds of when the page first starts loading.
									</p>
									<p><strong>Cumulative Layout Shift (CLS):</strong> measures visual
										stability. CLS measures the movement of visible elements within
										the viewport. To provide a good user experience, pages should
										maintain a CLS of less than 0.1.</p>
								</template>
							</div>
						</b-collapse>
					</template>

				</div>

				<div class="sf-cnt-section page-brake-before page-brake-after m-top-30" v-if="settings.view_options.mobile">
					<HeaderPdf v-if="isPdf" scoreBarHeaderID="scoreBarHeaderMobileAnalysis"
							   :logo_path="settings.logo_path"
							   :webpage=webpage
							   :overAllScore=overAllScore
							   :preparedBy=preparedBy
							   :score="data.overAllScore.toFixed(0)"
							   :name="settings.company_name"
							   :phone="settings.company_phone"
							   :email="settings.company_email"
							   :url="data.website"
					></HeaderPdf>
					<h2 class="sf-cnt-section__title">{{getLabel('Mobile Analysis')}}</h2>
					<template v-if="data.mobileAnalysis.loading">
						<Loading/>
					</template>
					<template v-else>
						<div class="sf-cnt-section__items">
							<div class="sf-cnt-section__item sf-cnt-section__item--info">
								<!--							<div class="sf-cnt-section__list">-->
								<!--								<div class="sf-cnt-section__list-item sf-cnt-section__list-item&#45;&#45;green">-->
								<!--									<div class="sf-cnt-section__list-txt">Your page is mobile-->
								<!--										friendly (responsive)-->
								<!--									</div>-->
								<!--								</div>-->
								<!--							</div>-->
								<p><a class="sf-cnt-section__subtitle">
									<span class="btn-txt">{{getLabel('Web Vitals')}}</span>
								</a></p>
								<div class="sf-cnt-section__cards sf-cnt-cards card-group">
									<div class="card">
										<div class="card-text card-text--small card-text--dark">
											{{getLabel('Large Contentful Paint')}}
										</div>
										<div :class="`card-num card-num--${rateClass(data.mobileAnalysis.largeContentfulPaint.rate)}`">
											<template v-if="data.mobileAnalysis.largeContentfulPaint.score !== null">
												{{data.mobileAnalysis.largeContentfulPaint.score.toFixed(1)}}
											</template>
										</div>
										<div class="card-text card-text--small">{{getLabel('Seconds')}}</div>
									</div>
									<div class="card">
										<div class="card-text card-text--small card-text--dark">
											{{getLabel('Cumulative Layout Shift')}}
										</div>
										<div :class="`card-num card-num--${rateClass(data.mobileAnalysis.cumulativeLayoutShift.rate)}`">
											<template v-if="data.mobileAnalysis.cumulativeLayoutShift.score !== null">
												{{data.mobileAnalysis.cumulativeLayoutShift.score}}
											</template>
										</div>
									</div>
								</div>
								<div class="card card-body sf-cnt-section__cnt">
									<div class="sf-cnt-section__list">
										<div v-if="data.mobileAnalysis.largeContentfulPaint.message && data.mobileAnalysis.largeContentfulPaint.score !== null"
											 class="sf-cnt-section__list-item"
											 :class="`sf-cnt-section__list-item--${rateClass(data.mobileAnalysis.largeContentfulPaint.rate)}`">
											<div class="sf-cnt-section__list-txt">
												{{getShortRecommendation('LargeContentfulPaint',
												data.mobileAnalysis.largeContentfulPaint.message,
												data.mobileAnalysis.largeContentfulPaint.rate)}}
											</div>
										</div>
										<div v-if="data.mobileAnalysis.cumulativeLayoutShift.message && data.mobileAnalysis.cumulativeLayoutShift.score !== null"
											 class="sf-cnt-section__list-item"
											 :class="`sf-cnt-section__list-item--${rateClass(data.mobileAnalysis.cumulativeLayoutShift.rate)}`">
											<div class="sf-cnt-section__list-txt">
												{{getShortRecommendation('CumulativeLayoutShift',
												data.mobileAnalysis.cumulativeLayoutShift.message,
												data.mobileAnalysis.cumulativeLayoutShift.rate)}}
											</div>
										</div>
									</div>
									<div class="alert alert-light noScreen" role="alert">
										<template v-if="shouldUseRecommendation('web_vitals')">
											{{getRecommendation('web_vitals')}}
										</template>
										<template v-else>
											<p>Web Vitals is an initiative by Google to provide unified guidance
												for quality signals that are essential to delivering a great
												user experience on the web.</p>
											<p><strong>Largest Contentful Paint (LCP):</strong> measures loading
												performance. LCP marks the time at which the largest text or
												image is painted. To provide a good user experience, LCP should
												occur within 2.5 seconds of when the page first starts loading.
											</p>
											<p><strong>Cumulative Layout Shift (CLS):</strong> measures visual
												stability. CLS measures the movement of visible elements within
												the viewport. To provide a good user experience, pages should
												maintain a CLS of less than 0.1.</p>
										</template>
									</div>
								</div>

							</div>
							<div v-if="data.mobileScreenshot" class="sf-cnt-section__img">
								<img class="sf-cnt-section__img__frame"
									 src="../../shared/assets/styles/images/mobile.svg" alt="Mobile Screenshot">
								<img class="sf-cnt-section__img__screenshot" :src="data.mobileScreenshot" alt="">
							</div>
						</div>
					</template>
				</div>

				<!--			Content-->
				<div class="sf-cnt-section" v-if="settings.view_options.content && (settings.view_options.titleTag || settings.view_options.descriptionTag || settings.view_options.imageAnalysis)">
					<div class=" page-brake-before m-top-30">
						<HeaderPdf v-if="isPdf" scoreBarHeaderID="scoreBarHeaderTitleTag"
								   :logo_path="settings.logo_path"
								   :webpage=webpage
								   :overAllScore=overAllScore
								   :preparedBy=preparedBy
								   :score="data.overAllScore.toFixed(0)"
								   :name="settings.company_name"
								   :phone="settings.company_phone"
								   :email="settings.company_email"
								   :url="data.website"
						></HeaderPdf>
						<h2 class="sf-cnt-section__title">{{getLabel('Content')}}</h2>
						<div class="sf-cnt-section__block" v-if="settings.view_options.titleTag">
							<p>
								<a class="sf-cnt-section__subtitle cursor-pointer" v-b-toggle.titleTag>
									<span class="btn-txt">{{getLabel('Title Tag')}}</span>
									<span class="btn-icon"><i class="icon-arrow-down"></i></span></a>
							</p>
							<div class="card card-body sf-cnt-section__cnt">
								<div class="sf-cnt-section__list mb-0">
									<div v-if="data.titleTag.value" class="sf-cnt-section__list-item">
										<div class="alert alert-secondary" role="alert">
											<p>{{data.titleTag.value}}</p>
										</div>
									</div>
									<div class="sf-cnt-section__list-item"
										 :class="`sf-cnt-section__list-item--${rateClass(data.titleTag.rate)}`">
										<div class="sf-cnt-section__list-txt">
											{{getShortRecommendation('TitleTag', data.titleTag.message,
											data.titleTag.rate)}}
										</div>
									</div>
								</div>
							</div>
							<b-collapse id="titleTag" class="sf-cnt-section__info">
								<div class="alert alert-light" role="alert">
									<template v-if="shouldUseRecommendation('page_title')">
										{{getRecommendation('page_title')}}
									</template>
									<template v-else>
										<p>Titles give your potential customers a quick insight into the
											content of a result and why its relevant to their query.
											Many times It's the primary piece of information used to
											decide which result to click on, so it's essential to use
											high-quality titles on your web pages. Page titles should be
											descriptive and concise. Avoid vague descriptors like "Home"
											for your home page, or "Profile" for a specific person.
											Avoid unnecessarily long or verbose titles, which are likely
											to get truncated when they show up in the search results Try
											to keep page titles short, i.e. less than 60 characters.</p>
									</template>
								</div>
							</b-collapse>
						</div>
						<div class="sf-cnt-section__block" v-if="settings.view_options.descriptionTag">
							<p>
								<a class="sf-cnt-section__subtitle cursor-pointer" v-b-toggle.descriptionTag>
									<span class="btn-txt">{{getLabel('Description Tag')}}</span>
									<span class="btn-icon"><i class="icon-arrow-down"></i></span></a>
							</p>
							<div class="card card-body sf-cnt-section__cnt">
								<div class="sf-cnt-section__list mb-0">
									<div v-if="data.descriptionTag.value" class="sf-cnt-section__list-item">
										<div class="alert alert-secondary" role="alert">
											<p>{{data.descriptionTag.value}}</p>
										</div>
									</div>
									<div class="sf-cnt-section__list-item"
										 :class="`sf-cnt-section__list-item--${rateClass(data.descriptionTag.rate)}`">
										<div class="sf-cnt-section__list-txt">
											{{getShortRecommendation('DescriptionTag', data.descriptionTag.message,
											data.descriptionTag.rate)}}
										</div>
									</div>
								</div>
							</div>
							<b-collapse id="descriptionTag" class="sf-cnt-section__info">
								<div class="alert alert-light" role="alert">
									<template v-if="shouldUseRecommendation('meta_description')">
										{{getRecommendation('meta_description')}}
									</template>
									<template v-else>
										<p>A description tag is nothing but a concise, human-readable
											summary of each page's content. Accurate meta descriptions
											can help improve your clickthrough; here are some guidelines
											on how to use the meta description properly. Use site-level
											descriptions on the main home page or other aggregation
											pages, and use page-level descriptions everywhere else.</p>
									</template>
								</div>
							</b-collapse>
						</div>
						<div class="sf-cnt-section__block" v-if="settings.view_options.imageAnalysis">
							<p>
								<a class="sf-cnt-section__subtitle cursor-pointer" v-b-toggle.imageAnalysis>
									<span class="btn-txt">{{getLabel('Image Analysis')}}</span>
									<span class="btn-icon"><i class="icon-arrow-down"></i></span></a>
							</p>

							<div class="card card-body sf-cnt-section__cnt">
								<div class="sf-cnt-section__list mb-0">
									<div v-if="data.imagesCount != null" class="sf-cnt-section__list-item">
										<div class="alert alert-secondary" role="alert">
											<p>{{data.imagesCount}} {{getLabel('images')}}</p>
										</div>
									</div>

									<div class="sf-cnt-section__list-item"
										 :class="`sf-cnt-section__list-item--${rateClass(data.altImagesCount.rate)}`">
										<div class="sf-cnt-section__list-txt">
											{{getShortRecommendation('ImageAltText', data.altImagesCount.message,
											data.altImagesCount.rate, data.altImagesCount.value)}}
										</div>
									</div>

									<div v-if="data.properlySizedImages.rate" class="sf-cnt-section__list-item"
										 :class="`sf-cnt-section__list-item--${rateClass(data.properlySizedImages.rate)}`">
										<div class="sf-cnt-section__list-txt">
											{{getShortRecommendation('ImageAnalyses',
											data.properlySizedImages.message, data.properlySizedImages.rate)}}
										</div>
									</div>

									<div v-if="data.offscreenImages.rate" class="sf-cnt-section__list-item"
										 :class="`sf-cnt-section__list-item--${rateClass(data.offscreenImages.rate)}`">
										<div class="sf-cnt-section__list-txt">
											{{getShortRecommendation('DeferOffscreenImages', data.offscreenImages.message,
											data.offscreenImages.rate)}}
										</div>
									</div>

									<div v-if="data.efficientlyEncodeImages.rate" class="sf-cnt-section__list-item"
										 :class="`sf-cnt-section__list-item--${rateClass(data.efficientlyEncodeImages.rate)}`">
										<div class="sf-cnt-section__list-txt">
											{{getShortRecommendation('EfficientlyEncodeImages',
											data.efficientlyEncodeImages.message, data.efficientlyEncodeImages.rate)}}
										</div>
									</div>

								</div>
							</div>
							<b-collapse id="imageAnalysis" class="sf-cnt-section__info">
								<div class="alert alert-light" role="alert">
									<template v-if="shouldUseRecommendation('image_alt_text')">
										{{getRecommendation('image_alt_text')}}
									</template>
									<template v-else>
										<p>For any image on your site, the alt tag should describe what
											on it. Alt tags and title tags strengthen the message
											towards search engine spiders and improve the accessibility
											of your website.
										</p>
									</template>
								</div>
							</b-collapse>
						</div>
					</div>


					<HeaderPdf v-if="isPdf" class="page-brake-before m-top-70"
							   scoreBarHeaderID="scoreBarHeaderCopyAnalysis"
							   :webpage=webpage
							   :overAllScore=overAllScore
							   :preparedBy=preparedBy
							   :logo_path="settings.logo_path"
							   :score="data.overAllScore.toFixed(0)"
							   :name="settings.company_name"
							   :phone="settings.company_phone"
							   :email="settings.company_email"
							   :url="data.website"
					></HeaderPdf>

					<h2 class="sf-cnt-section__title noScreen ">{{getLabel('Content')}}</h2>

					<div class="sf-cnt-section__block " v-if="settings.view_options.copyAnalysis">
						<p>
							<a class="sf-cnt-section__subtitle">
								<span class="btn-txt">{{getLabel('Copy Analysis')}}</span>
							</a>
						</p>

						<div class="card card-body sf-cnt-section__cnt">
							<div class="sf-cnt-section__list mb-0">
								<div class="sf-cnt-section__list-item"
									 :class="`sf-cnt-section__list-item--${rateClass(data.wordsCount.rate)}`">
									<div class="sf-cnt-section__list-txt">
										{{getShortRecommendation('TotalWords',
										data.wordsCount.message,
										data.wordsCount.rate,
										data.wordsCount.value)}}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="sf-cnt-section__block">
						<p>
							<a class="sf-cnt-section__subtitle cursor-pointer" v-b-toggle.copyAnalysis>
								<span class="btn-txt">{{getLabel('Top 5 Words Used on this page')}}</span>
								<span class="btn-icon"><i class="icon-arrow-down"></i></span></a>
						</p>
						<div class="card card-body sf-cnt-section__cnt" v-if="data.topWords[0]">
							<div class="sf-cnt-section__progress">
								<div class="sf-cnt-section__progress-item">
									<div class="sf-cnt-section__progress-txt">
										{{data.topWords[0]['word']}} <span>{{data.topWords[0]['count']}}</span></div>
									<div class="progress">
										<div class="progress-bar" role="progressbar"
											 style="width: 75%" aria-valuenow="15"
											 aria-valuemin="0" aria-valuemax="20"></div>
									</div>
								</div>
								<div class="sf-cnt-section__progress-item" v-if="data.topWords[1]">
									<div class="sf-cnt-section__progress-txt">
										{{data.topWords[1]['word']}} <span>{{data.topWords[1]['count']}}</span>
									</div>
									<div class="progress">
										<div class="progress-bar" role="progressbar"
											 :style="`width: ${percentage(data.topWords[1]['count'])}%`"
											 aria-valuenow="11"
											 aria-valuemin="0" aria-valuemax="20"></div>
									</div>
								</div>
								<div class="sf-cnt-section__progress-item"  v-if="data.topWords[2]">
									<div class="sf-cnt-section__progress-txt">
										{{data.topWords[2]['word']}} <span>{{data.topWords[2]['count']}}</span>
									</div>
									<div class="progress">
										<div class="progress-bar" role="progressbar"
											 :style="`width: ${percentage(data.topWords[2]['count'])}%`"
											 aria-valuenow="10"
											 aria-valuemin="0" aria-valuemax="20"></div>
									</div>
								</div>
								<div class="sf-cnt-section__progress-item"  v-if="data.topWords[3]">
									<div class="sf-cnt-section__progress-txt">
										{{data.topWords[3]['word']}} <span>{{data.topWords[3]['count']}}</span>
									</div>
									<div class="progress">
										<div class="progress-bar" role="progressbar"
											 :style="`width: ${percentage(data.topWords[3]['count'])}%`"
											 aria-valuenow="8"
											 aria-valuemin="0" aria-valuemax="20"></div>
									</div>
								</div>
								<div class="sf-cnt-section__progress-item"  v-if="data.topWords[4]">
									<div class="sf-cnt-section__progress-txt">
										{{data.topWords[4]['word']}} <span>{{data.topWords[4]['count']}}</span>
									</div>
									<div class="progress">
										<div class="progress-bar" role="progressbar"
											 :style="`width: ${percentage(data.topWords[4]['count'])}%`"
											 aria-valuenow="8"
											 aria-valuemin="0" aria-valuemax="20"></div>
									</div>
								</div>
							</div>
						</div>
						<b-collapse id="copyAnalysis" class="sf-cnt-section__info">
							<div class="alert alert-light" role="alert">
								<template v-if="shouldUseRecommendation('total_words')">
									{{getRecommendation('total_words')}}
								</template>
								<template v-else>
									<p>This data represents top five words used on this page. Are
										these the keywords you want to target for your page? If so,
										great! If these keywords aren’t relevant to your page,
										consider updating your content to optimize it for your
										target keywords.
									</p>
								</template>
							</div>
						</b-collapse>
					</div>
					<div class="sf-cnt-section__block" v-if="settings.view_options.pageLinkAnalysis">
						<p>
							<a class="sf-cnt-section__subtitle cursor-pointer" v-b-toggle.pageLinkAnalysis>
								<span class="btn-txt">{{getLabel('Page Link Analysis')}}</span>
								<span class="btn-icon"><i class="icon-arrow-down"></i></span>
							</a>
						</p>
						<div class="card card-body sf-cnt-section__cnt">
							<div class="sf-cnt-section__list mb-0">
								<div class="sf-cnt-section__list-item"
									 :class="`sf-cnt-section__list-item--${rateClass(data.pageLinkAnalysis.internalLinks.rate)}`">
									<div class="sf-cnt-section__list-txt">
										{{getShortRecommendation('InternalLinks',
										data.pageLinkAnalysis.internalLinks.message,
										data.pageLinkAnalysis.internalLinks.rate,
										data.pageLinkAnalysis.internalLinks.count)}}
									</div>
								</div>
								<div class="sf-cnt-section__list-item"
									 :class="`sf-cnt-section__list-item--${rateClass(data.pageLinkAnalysis.externalLinks.rate)}`">
									<div class="sf-cnt-section__list-txt">
										{{getShortRecommendation('OutboundLinks',
										data.pageLinkAnalysis.externalLinks.message,
										data.pageLinkAnalysis.externalLinks.rate,
										data.pageLinkAnalysis.externalLinks.count)}}
									</div>
								</div>
								<div class="sf-cnt-section__list-item"
									 :class="`sf-cnt-section__list-item--${rateClass(data.pageLinkAnalysis.SEOFriendly.rate)}`">
									<div class="sf-cnt-section__list-txt">
										{{getShortRecommendation('SEOFriendlyLinks',
										data.pageLinkAnalysis.SEOFriendly.message,
										data.pageLinkAnalysis.SEOFriendly.rate)}}
									</div>
								</div>
							</div>
						</div>
						<b-collapse id="pageLinkAnalysis" class="sf-cnt-section__info">
							<div class="alert alert-light" role="alert">
								<template v-if="shouldUseRecommendation('seo_friendly_links')">
									{{getRecommendation('seo_friendly_links')}}
								</template>
								<template v-else>
									<p>An SEO friendly link follows these basic rules: The URL
										should use a dash as a separator, not use parameters or
										numbers, and should be a static URL. To resolve this, use
										these techniques. 1) Replace underscores or other separators
										with a dash, clean URL by deleting or replacing numbers and
										parameters. 2) Marge your www and non www URLs. 3) Do not
										use dynamic and related URLs. Create an XML sitemap for
										proper indexing by search engines. 4) Block unfriendly and
										irrelevant links with robots.txt 5) Endorse your URLs in
										canonical tags.
									</p>
								</template>
							</div>
						</b-collapse>
					</div>
				</div>
				<div class=" page-brake-before sf-cnt-section"  v-if="settings.view_options.indexing || settings.view_options.codeAnalysis">
					<HeaderPdf v-if="isPdf" class="m-top-30"
							   scoreBarHeaderID="scoreBarHeaderIndexing"
							   :logo_path="settings.logo_path"
							   :webpage=webpage
							   :overAllScore=overAllScore
							   :preparedBy=preparedBy
							   :score="data.overAllScore.toFixed(0)"
							   :name="settings.company_name"
							   :phone="settings.company_phone"
							   :email="settings.company_email"
							   :url="data.website"
					></HeaderPdf>
					<div v-if="settings.view_options.indexing">

						<h2 class="sf-cnt-section__title">{{getLabel('Indexing')}}</h2>
						<div class="sf-cnt-section__block" v-if="settings.view_options.robotTxt">
							<p>
								<a class="sf-cnt-section__subtitle cursor-pointer" v-b-toggle.robotsTxt>
									<span class="btn-txt">{{getLabel('Robots.txt')}}</span>
									<span class="btn-icon"><i class="icon-arrow-down"></i></span>
								</a>
							</p>
							<div v-if="data.indexing.robotTxt.rate" class="sf-cnt-section__list-item"
								 :class="`sf-cnt-section__list-item--${rateClass(data.indexing.robotTxt.rate)}`">
								<div class="sf-cnt-section__list-txt">
									{{getShortRecommendation('Robots.txt', data.indexing.robotTxt.message,
									data.indexing.robotTxt.rate)}}
								</div>
							</div>
							<b-collapse id="robotsTxt" class="sf-cnt-section__info">
								<div class="alert alert-light" role="alert">
									<template v-if="shouldUseRecommendation('robot_txt')">
										{{getRecommendation('robot_txt')}}
									</template>
									<template v-else>
										<p>A robots.txt file is a file at the root of your site that
											indicates those parts of your site you do not want to be
											accessed by search engine crawlers. The file uses the Robots
											Exclusion Standard, which is a protocol with a small set of
											commands that can be used to indicate access to your site by
											section and by specific kinds of web crawlers (such as
											mobile crawlers vs desktop crawlers).
										</p>
									</template>
								</div>
							</b-collapse>
						</div>
						<div class="sf-cnt-section__block" v-if="settings.view_options.siteMap">
							<p>
								<a class="sf-cnt-section__subtitle cursor-pointer" v-b-toggle.sitemap>
									<span class="btn-txt">{{getLabel('Sitemap')}}</span>
									<span class="btn-icon"><i class="icon-arrow-down"></i></span>
								</a>
							</p>
							<div v-if="data.indexing.sitemap.rate" class="sf-cnt-section__list-item"
								 :class="`sf-cnt-section__list-item--${rateClass(data.indexing.sitemap.rate)}`">
								<div class="sf-cnt-section__list-txt">
									{{getShortRecommendation('Sitemap', data.indexing.sitemap.message,
									data.indexing.sitemap.rate)}}
								</div>
							</div>
							<b-collapse id="sitemap" class="sf-cnt-section__info">
								<div class="alert alert-light" role="alert">
									<template v-if="shouldUseRecommendation('sitemap')">
										{{getRecommendation('sitemap')}}
									</template>
									<template v-else>
										<p>A sitemap is a file where you can list the web pages of your
											site to tell search engines about the arrangement of your
											site content. Search engine web crawlers read this file to
											more intelligently crawl your site. Sitemaps can be created
											either manually or using third-party tools. Using a sitemap
											does not guarantee that all the items in your sitemap will
											be crawled and indexed, as Google processes rely on
											sophisticated algorithms to schedule crawling. However, in
											most cases, your site will benefit from having a sitemap,
											and you'll never be penalized for having one.
										</p>
									</template>
								</div>
							</b-collapse>
						</div>
					</div>
					<div class="mt-60" v-if="settings.view_options.codeAnalysis">
						<h2 class="sf-cnt-section__title">{{getLabel('Code Analysis')}}</h2>
						<div class="sf-cnt-section__list mb-0">
							<div v-if="data.codeAnalysis.deprecatedTags.rate" class="sf-cnt-section__list-item"
								 :class="`sf-cnt-section__list-item--${rateClass(data.codeAnalysis.deprecatedTags.rate)}`">
								<div class="sf-cnt-section__list-txt">
									{{getShortRecommendation('Sitemap', data.codeAnalysis.deprecatedTags.message,
									data.codeAnalysis.deprecatedTags.rate)}}
								</div>
							</div>

							<div v-if="data.codeAnalysis.pageSize.rate" class="sf-cnt-section__list-item"
								 :class="`sf-cnt-section__list-item--${rateClass(data.codeAnalysis.pageSize.rate)}`">
								<div class="sf-cnt-section__list-txt">
									{{getShortRecommendation('HtmlPageSize', data.codeAnalysis.pageSize.message,
									data.codeAnalysis.pageSize.rate)}}
								</div>
							</div>

							<div v-if="data.codeAnalysis.textHtmlRatio.rate" class="sf-cnt-section__list-item"
								 :class="`sf-cnt-section__list-item--${rateClass(data.codeAnalysis.textHtmlRatio.rate)}`">
								<div class="sf-cnt-section__list-txt">
									{{getShortRecommendation('TextHtmlRatio', data.codeAnalysis.textHtmlRatio.message,
									data.codeAnalysis.textHtmlRatio.rate)}}
								</div>
							</div>

							<div v-if="data.codeAnalysis.GzipCompression.rate" class="sf-cnt-section__list-item"
								 :class="`sf-cnt-section__list-item--${rateClass(data.codeAnalysis.GzipCompression.rate)}`">
								<div class="sf-cnt-section__list-txt">
									{{getShortRecommendation('GzipCompression',
									data.codeAnalysis.GzipCompression.message,
									data.codeAnalysis.GzipCompression.rate)}}
								</div>
							</div>

							<div v-if="data.codeAnalysis.inlineCss.rate" class="sf-cnt-section__list-item"
								 :class="`sf-cnt-section__list-item--${rateClass(data.codeAnalysis.inlineCss.rate)}`">
								<div class="sf-cnt-section__list-txt">
									{{getShortRecommendation('InlineCSS', data.codeAnalysis.inlineCss.message,
									data.codeAnalysis.inlineCss.rate, data.codeAnalysis.inlineCss.value )}}
								</div>
							</div>

							<div v-if="data.codeAnalysis.internalCss.rate" class="sf-cnt-section__list-item"
								 :class="`sf-cnt-section__list-item--${rateClass(data.codeAnalysis.internalCss.rate)}`">
								<div class="sf-cnt-section__list-txt">
									{{getShortRecommendation('InternalCSS', data.codeAnalysis.internalCss.message,
									data.codeAnalysis.internalCss.rate, data.codeAnalysis.internalCss.value)}}
								</div>
							</div>

							<div v-if="data.codeAnalysis.microData.rate" class="sf-cnt-section__list-item"
								 :class="`sf-cnt-section__list-item--${rateClass(data.codeAnalysis.microData.rate)}`">
								<div class="sf-cnt-section__list-txt">
									{{getShortRecommendation('MicroDataSchemaTest', data.codeAnalysis.microData.message,
									data.codeAnalysis.microData.rate)}}
								</div>
							</div>

							<div v-if="data.codeAnalysis.IpCanonicalization.rate" class="sf-cnt-section__list-item"
								 :class="`sf-cnt-section__list-item--${rateClass(data.codeAnalysis.IpCanonicalization.rate)}`">
								<div class="sf-cnt-section__list-txt">
									{{getShortRecommendation('IPCanonicalizationTest',
									data.codeAnalysis.IpCanonicalization.message,
									data.codeAnalysis.IpCanonicalization.rate)}}
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
    import Loading from "./Loading";
    import {RATE} from "../constants/recommendations";
    import {VIEW_REPORT} from "../constants";
    import {conversionTrackingMixin} from "../mixins/conversion-tracking-mixin";
    import * as ProgressBar from "progressbar.js";
    import HeaderPdf from "./HeaderPdf";

    export default {
        name: "ReportContent",
        mixins: [conversionTrackingMixin],
        components: {HeaderPdf, Loading},
        props: {
            isPdf: {
                type: Boolean,
                default: false
            },
            settings: {
                type: Object,
            },
            data: {
                type: Object
            }
        },
		data() {
            return {
                bar: null
			}
		},
        created() {
            this.$nextTick(() => {
                this.addConversionTrackingScripts();
            });
        },
        mounted() {
            this.setScoreCircle(this.isPdf);
		},
        updated() {
            this.bar.setText(this.data.overAllScore.toFixed(0) + '<span>/100</span>');
            this.bar.set(this.data.overAllScore / 100);  // Number from 0.0 to 1.0
        },

        methods: {
            rateClass(rate) {
                switch (rate) {
                    case RATE.GOOD:
                        return 'green';
                    case RATE.BAD:
                        return 'red';
                    case RATE.AVERAGE:
                        return 'yellow'
                }

            },
            addConversionTrackingScripts() {
                if (this.settings.view_analytics && (! (Object.prototype.hasOwnProperty.call(this.settings, 'do_not_add_conversion_scripts') && this.settings.do_not_add_conversion_scripts ))) {

                    this.setConversionScripts(this.settings.analytics_script);
                }
			},
            createElementFromHTML(htmlString) {
                let div = document.createElement('div');
                div.innerHTML = htmlString;
                return div.childNodes;
            },
            percentage(x) {
                return (x * 75) / this.data.topWords[0]['count'];
            },
            getLabel(title) {
                let arr = title.split(' ');

                let index = arr[0];
                arr.shift();
                for (let i = 0; i < arr.length; i++) {
                    index += (arr[i].charAt(0).toUpperCase() + arr[i].slice(1));
                }

                if (this.settings.custom_language && Object.prototype.hasOwnProperty.call(this.settings.labelNames, index) && this.settings.labelNames[index] !== '') {
                    return this.settings.labelNames[index];
                } else return title;
            },

            getShortRecommendation(index, defaultMessage, rate, count = null) {
                if (rate !== null && rate !== undefined) {
                    index += rate.toLowerCase();
				}

                if (this.settings.custom_language && Object.prototype.hasOwnProperty.call(this.settings.shortRecommendations, index) && this.settings.shortRecommendations[index] !== '') {
                    return count !== null ? this.settings.shortRecommendations[index].replace(':100', count) : this.settings.shortRecommendations[index];
                } else return defaultMessage;
            },
            getRecommendation(index) {
                return this.settings.recommendations[index];
            },
            shouldUseRecommendation(index) {
                return this.settings.custom_language && Object.prototype.hasOwnProperty.call(this.settings.recommendations, index) && this.settings.shortRecommendations[index] !== '';

            },
			setScoreCircle(withoutAnimation) {
                var that = this;
                this.bar = new ProgressBar.Circle('#scoreBar', {
                    strokeWidth: 18,
                    easing: 'easeInOut',
                    duration: 1400,
                    color: 'rgb(80, 213, 133)',
                    trailColor: '#eee',
                    trailWidth: 9,
                    svgStyle: null,

                    text: {
                        // Initial value for text.
                        // Default: null
                        value: that.data.overAllScore.toFixed(0) + '<span>/100</span>',

                        // Class name for text element.
                        // Default: 'progressbar-text'
                        className: 'progress-value',
                        style: {
                            position: 'absolute',
                            left: '50%',
                            top: '50%',
                            padding: '0px',
                            margin: '0px',
                            transform: 'translate(-50%, -50%)',
                            color: '#15394f'
                        }
                    },

                });

                if (withoutAnimation) {
                    this.bar.set(this.data.overAllScore / 100);  // Number from 0.0 to 1.0
                } else {
                    this.bar.animate(this.data.overAllScore / 100);  // Number from 0.0 to 1.0
                }
			}
        },
        computed: {
            showReport() {
                return this.settings.view_report === VIEW_REPORT.FULL_REPORT;
            },
            showFirstPageOnly() {
                return this.settings.view_report === VIEW_REPORT.FIRST_PAGE_ONLY;
            },
            doNotShowReport() {
                return this.settings.view_report === VIEW_REPORT.DONT_DISPLAY;
            },
            webpage() {
                return this.getLabel('Webpage');
            },
            overAllScore() {
                return this.getLabel('Overall score');
            },
            preparedBy() {
                return this.getLabel('Prepared by');
            }
        }
    }
</script>

<style scoped>
	.cursor-pointer:hover {
		cursor: pointer;
	}

	.page-brake-before {
		page-break-before: always;
	}

	.page-brake-after {
		page-break-after: always;
	}

	.mt-60 {
		margin-top: 60px;
	}

	@media screen {
		.noScreen {
			display: none;
		}
	}

</style>


<template>
	<div>
		<PageHeader title="Format Report" :show-settings-button="false"/>
		<section class="setup-form" v-if="dataLoaded">
			<div class="container">
				<div class="row">
					<div class="col-lg-4 col-md-12">
						<div class="build-form">
							<div class="sf-head">
								<h3>Design Report Appearance</h3>
							</div>
							<div class="bf-btns">
								<button class="btn btn-outline-secondary" @click="openResetModal">Reset</button>
								<button class="btn btn-primary" :disabled="savingProcessing" @click="save">Save</button>
							</div>
							<div class="bf-cnt">
								<div class="accordion" id="accordion">
									<Accordion title="Overview" ident="overview">
										<div class="sf-item">
											<RadioGroup
													id="view_report"
													v-model="reportSettings.view_report"
													:options="viewReportOptions"
													label="Report Display"
											/>
											<template v-if="!showReport">
												<div class="alert alert-secondary form-group show" role="alert">What
													should your user see after submitting the form?
												</div>
												<MyInput v-if="showFirstPageOnly" label="Thank you Message"
														 :required='true'
														 placeholder="Add Message"
														 :value="reportSettings.score_response_message"
														 id="score_response_message"
														 key="score_response_message"
														 v-model="reportSettings.score_response_message"
														 :error="errors.score_response_message_error"
														 @input="validateSettings"
														 :debounce="500"
												/>
												<MyInput v-else label="Thank you Message"
														 :required='true'
														 placeholder="Add Message"
														 :value="reportSettings.index_response_message"
														 id="index_response_message"
														 key="index_response_message"
														 v-model="reportSettings.index_response_message"
														 :error="errors.index_response_message_error"
														 @input="validateSettings"
														 :debounce="500"
												/>

												<MyInput label="Redirect URL (Optional)"
														 placeholder="Add URL"
														 :value="reportSettings.redirect_url"
														 id="redirect_url"
														 key="redirect_url"
														 v-model="reportSettings.redirect_url"
												/>
												<MyInput label="Time to redirect (In Seconds)"
														 placeholder="10"
														 type="number"
														 :value="reportSettings.redirect_url_time"
														 id="redirect_url_time"
														 key="redirect_url_time"
														 v-model="reportSettings.redirect_url_time"
												/>
											</template>

										</div>
										<div v-if="showReport" class="sf-item">
											<RadioGroup
													id="redirect_after_downlaod"
													v-model="reportSettings.redirect_after_downlaod"
													:options="yesNoOptions"
													label="Redirect URL after Downloading Report"
													:inline="true"
											/>
											<MyInput v-if="reportSettings.redirect_after_downlaod"
													 placeholder="http://www.example.com/redirectpage"
													 :value="reportSettings.redirect_afterdownload_url"
													 id="redirect_afterdownload_url"
													 :error="errors.redirect_url_error"
													 key="redirect_afterdownload_url"
													 v-model="reportSettings.redirect_afterdownload_url"
											/>
										</div>
										<div class="sf-item">
											<div class="form-group">
												<label>Upload Favicon</label>
												<UploadImage :path="reportSettings.favicon_path"
															 v-model="reportSettings.favicon_path"
															 :maxSize="51200"
															 :maxWidth="16"
															 :maxHeight="16"
															 :fileTypes="faviconFileTypes"
															 type="favicon"
															 :key="reportSettings.favicon_path">
												</UploadImage>
											</div>
										</div>
										<div class="sf-item">
											<Select id="choose-font-family"
													label="Choose Font Family"
													:options="fontOptions"
													:key="reportSettings.custom_font"
													v-model="reportSettings.custom_font"
													:selectedOption="reportSettings.custom_font">
											</Select>
										</div>
										<div class="sf-item">
											<div class="form-group">
												<label>Show the Report in Any Language</label>
												<p class="form-group__txt">Follow the setup instruction mentioned <a
														class="blue-link"
														href="https://siteauditor.com/siteauditor-in-any-language"
														target="_blank">here</a></p>
												<ol class="form-group__list">
													<li class="form-group__list-item active">
														<a class="blue-link" download=""
														   href="/files/Siteauditor_language_template.xlsx">
															Click here</a> to download the template
													</li>
													<li class="form-group__list-item active">
														Paste the Google sheet URL and save
													</li>
												</ol>
												<MyInput placeholder="Input Google sheet URL"
														 v-model="reportSettings.recommendation_sheet_url"
														 id="recommendation_sheet_url"
														 :value="reportSettings.recommendation_sheet_url"
														 :debounce="1000"
														 :error="errors.recommendation_error"
														 @keyup.enter.native="getRecommendations"
														 @input="getRecommendations">

												</MyInput>
											</div>
										</div>
									</Accordion>
									<Accordion title="Header" ident="header">
										<div class="sf-item">
											<div class="form-group">
												<label>Upload Company Logo</label>
												<UploadImage :path="reportSettings.logo_path"
															 v-model="reportSettings.logo_path"
															 type="logo"
															 :key="reportSettings.logo_path">
												</UploadImage>
											</div>
										</div>
										<div class="sf-item">
											<MyInput placeholder="Company Name"
													 label="Company Name"
													 :value="reportSettings.company_name"
													 id="company_name"
													 :key="'company_name-' + keyForceUpdate"
													 v-model="reportSettings.company_name"
											/>
											<MyInput placeholder="Company Email"
													 label="Company Email"
													 type="email"
													 :value="reportSettings.company_email"
													 id="company_email"
													 :key="'company_email-' + keyForceUpdate"
													 v-model="reportSettings.company_email"
											/>
											<MyInput placeholder="Company Phone"
													 label="Company Phone"
													 :value="reportSettings.company_phone"
													 id="company_phone"
													 :key="'company_phone-' + keyForceUpdate"
													 v-model="reportSettings.company_phone"
											/>
										</div>
										<div class="sf-item">
											<RadioGroup
													id="show_branding"
													v-model="reportSettings.show_powered_by"
													:options="brandingOptions"
													label="Powered by SiteAuditor.com"
													:inline="true"
											/>
										</div>
									</Accordion>
									<Accordion title="Fields" ident="fields" icon="fields">
										<div class="sf-item">
											<div class="accordion">
												<b-card no-body>
													<b-card-header>
														<div class="custom-control custom-switch">
															<input class="custom-control-input" id="DesktopAnalysis"
																   type="checkbox"
																   v-model="reportSettings.view_options.desktop">
															<label class="custom-control-label"
																   for="DesktopAnalysis"></label>
														</div>
														<button class="btn btn-link btn-block text-left" type="button">
															<span class="btn-txt">Desktop Analysis</span>
														</button>
													</b-card-header>
												</b-card>
												<b-card no-body>
													<b-card-header>
														<div class="custom-control custom-switch">
															<input class="custom-control-input" id="MobileAnalysis"
																   type="checkbox"
																   v-model="reportSettings.view_options.mobile">
															<label class="custom-control-label"
																   for="MobileAnalysis"></label>
														</div>
														<button class="btn btn-link btn-block text-left" type="button">
															<span class="btn-txt">Mobile Analysis</span>
														</button>
													</b-card-header>
												</b-card>
												<b-card no-body>
													<b-card-header role="collapse">
														<div class="custom-control custom-switch">
															<input class="custom-control-input" id="content"
																   type="checkbox"
																   v-model="reportSettings.view_options.content">
															<label class="custom-control-label"
																   for="content"></label>
														</div>
														<button class="btn btn-link btn-block text-left" type="button"
																v-b-toggle="`accordion-content`">
															<span class="btn-txt">Content</span>
															<span class="btn-icon"><i
																	class="icon-arrow-down"></i></span>
														</button>
													</b-card-header>
													<b-collapse :id="`accordion-content`" visible role="tabpanel">
														<b-card-body>
															<div class="custom-control custom-switch">
																<input class="custom-control-input"
																	   type="checkbox"
																	   id="contentTitleTag"
																	   v-model="reportSettings.view_options.titleTag">
																<label class="custom-control-label"
																	   for="contentTitleTag"><span>Title Tag</span></label>
															</div>
															<div class="custom-control custom-switch">
																<input class="custom-control-input"
																	   type="checkbox"
																	   id="contentDescriptionTag"
																	   v-model="reportSettings.view_options.descriptionTag">
																<label class="custom-control-label"
																	   for="contentDescriptionTag"><span>Description Tag</span></label>
															</div>
															<div class="custom-control custom-switch">
																<input class="custom-control-input"
																	   type="checkbox"
																	   id="contentImageAnalysis"
																	   v-model="reportSettings.view_options.imageAnalysis">
																<label class="custom-control-label"
																	   for="contentImageAnalysis"><span>Image Analysis</span></label>
															</div>
															<div class="custom-control custom-switch">
																<input class="custom-control-input"
																	   type="checkbox"
																	   id="contentCopyAnalysis"
																	   v-model="reportSettings.view_options.copyAnalysis">
																<label class="custom-control-label"
																	   for="contentCopyAnalysis"><span>Copy Analysis</span></label>
															</div>
															<div class="custom-control custom-switch">
																<input class="custom-control-input"
																	   type="checkbox"
																	   id="contentPageLinkAnalysis"
																	   v-model="reportSettings.view_options.pageLinkAnalysis">
																<label class="custom-control-label"
																	   for="contentPageLinkAnalysis"><span>Page Link Analysis</span></label>
															</div>
														</b-card-body>
													</b-collapse>
												</b-card>

												<b-card no-body>
													<b-card-header role="collapse">
														<div class="custom-control custom-switch">
															<input class="custom-control-input" id="indexing"
																   type="checkbox"
																   v-model="reportSettings.view_options.indexing">
															<label class="custom-control-label"
																   for="indexing"></label>
														</div>
														<button class="btn btn-link btn-block text-left" type="button"
																v-b-toggle="`accordion-indexing`">
															<span class="btn-txt">Indexing</span>
															<span class="btn-icon"><i
																	class="icon-arrow-down"></i></span>
														</button>
													</b-card-header>
													<b-collapse :id="`accordion-indexing`" visible role="tabpanel">
														<b-card-body>
															<div class="custom-control custom-switch">
																<input class="custom-control-input"
																	   type="checkbox"
																	   id="indexingRobot"
																	   v-model="reportSettings.view_options.robotTxt">
																<label class="custom-control-label"
																	   for="indexingRobot"><span>Robots.txt</span></label>
															</div>
															<div class="custom-control custom-switch">
																<input class="custom-control-input"
																	   type="checkbox"
																	   id="indexingSitemap"
																	   v-model="reportSettings.view_options.siteMap">
																<label class="custom-control-label"
																	   for="indexingSitemap"><span>Sitemap</span></label>
															</div>
														</b-card-body>
													</b-collapse>
												</b-card>
												<b-card no-body>
													<b-card-header>
														<div class="custom-control custom-switch">
															<input class="custom-control-input" id="CodeAnalysis"
																   type="checkbox"
																   v-model="reportSettings.view_options.codeAnalysis">
															<label class="custom-control-label"
																   for="CodeAnalysis"></label>
														</div>
														<button class="btn btn-link btn-block text-left" type="button">
															<span class="btn-txt">Code Analysis</span>
														</button>
													</b-card-header>
												</b-card>
											</div>
										</div>
									</Accordion>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-8 col-md-12">
						<div class="sf-main">
							<div class="sf-head">
								<h3>Report Preview</h3>
							</div>
							<div class="sf-cnt">
								<ReportPreview :report-settings="reportSettings"></ReportPreview>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<Modal modal-id="reset-to-defaults"
			   submit-button-text="Reset"
			   cancel-button-text="Cancel"
			   :hideTitle="true"
			   :handleOk="resetAndSave"
		>
			<div>
				<h2>Do you want to reset settings to defaults?</h2>
			</div>
		</Modal>
		<Modal modal-id="not-saved-changes" classNames="modal-dialog--big"
			   :hideTitle="true"
			   :hideButtons="true">
			<div>
				<p class="center">Before moving to the other page</p>
				<h2 class="center">Do you want to save your edits?</h2>
				<div class="modal-btns">
					<button class="btn btn-primary" :disabled="savingProcessing" type="button" @click="saveAndRedirect">Save</button>
					<button class="btn btn-secondary" type="button" @click="redirect">Do Not Save</button>
				</div>
			</div>
		</Modal>
		<Modal modal-id="not-filled-mandatory-changes" classNames="modal-dialog--big"
			   :hideTitle="true"
			   :hideButtons="true">
			<div>
				<p class="center">Before moving to the other page</p>
				<h2 class="center">Make sure to complete the required fields</h2>
				<div class="modal-btns">
					<button class="btn btn-primary" :disabled="savingProcessing" type="button" @click="focusOnError">Continue Editing</button>
					<button class="btn btn-secondary" type="button" @click="redirect">Do Not Save</button>
				</div>
			</div>
		</Modal>
	</div>

</template>

<script>


    import PageHeader from "../../../../../shared/components/PageHeader";
    import Accordion from "../../../../../shared/components/Accordion";
    import RadioGroup from "../../../../../shared/components/RadioGroup";
    import MyInput from "../../../../../shared/components/Input";
    import Modal from "../../../../../shared/components/Modal";
    import Select from "../../../../../shared/components/Select";

    import {VIEW_REPORT, FONT_NAMES} from "../../../../../shared/constants";
    import UploadImage from "../../../../../shared/components/UploadImage";
    import {validate} from "vee-validate";
    import {mapGetters} from "vuex";
    import ReportPreview from "./ReportPreview";

    import _ from 'lodash';


    export default {
        name: "format-report",
        components: {ReportPreview, UploadImage, Accordion, PageHeader, RadioGroup, MyInput, Select, Modal},

        data() {
            return {
                keyForceUpdate: 0,

                dataLoaded: false,
                savingProcessing: false,
                valid: true,
                viewReportOptions: [
                    {
                        value: VIEW_REPORT.FULL_REPORT,
                        text: "Display Full Report",
                    },
                    {
                        value: VIEW_REPORT.FIRST_PAGE_ONLY,
                        text: "Display First Page Only",
                    },
                    {
                        value: VIEW_REPORT.DONT_DISPLAY,
                        text: "Don’t Display Report",
                    },
                ],
                yesNoOptions: [
                    {value: 1, text: 'Yes'},
                    {value: 0, text: 'No'},
                ],
                brandingOptions: [
                    {value: 1, text: 'Show'},
                    {value: 0, text: 'Hide'},
                ],
                faviconFileTypes: [
                    "image/jpeg",
                    "image/jpg",
                    "image/png",
                    "image/gif",
                    "image/ico"
                ],
                viewOptions: {},
                defaultSettings: {
                    view_options: {
                        desktop: true,
                        mobile: true,
                        content: true,
                        titleTag: true,
                        descriptionTag: true,
                        imageAnalysis: true,
                        copyAnalysis: true,
                        pageLinkAnalysis: true,
                        indexing: true,
                        robotTxt: true,
                        siteMap: true,
                        codeAnalysis: true,
                    },
				},
                reportSettings: {
                    do_not_add_conversion_scripts : true,
                    view_report: VIEW_REPORT.FULL_REPORT,
                    redirect_after_downlaod: 0,
                    redirect_afterdownload_url: '',

                    index_response_message: '',
                    score_response_message: '',
                    redirect_url: '',
                    redirect_url_time: 10,
                    favicon_path: '',
                    custom_font_radio: true,
                    custom_font: FONT_NAMES.OPEN_SANS,
                    recommendation_sheet_url: '',

                    custom_language: 0,

                    show_powered_by: 1,

                    logo_path: '',
                    company_name: '',
                    company_email: '',
                    company_phone: '',

                    view_options: {
                        desktop: true,
                        mobile: true,
                        content: true,
                        titleTag: true,
                        descriptionTag: true,
                        imageAnalysis: true,
                        copyAnalysis: true,
                        pageLinkAnalysis: true,
                        indexing: true,
                        robotTxt: true,
                        siteMap: true,
                        codeAnalysis: true,
                    },
                    recommendations: {},
                    labelNames: {},
                    shortRecommendations: {},

                },
                fontOptions: [
                    {value: FONT_NAMES.ROBOTO, text: FONT_NAMES.ROBOTO},
                    {value: FONT_NAMES.OPEN_SANS, text: FONT_NAMES.OPEN_SANS},
                    {value: FONT_NAMES.LATO, text: FONT_NAMES.LATO},
                    {value: FONT_NAMES.AVENIR_NEXT_LPRO_REGULAR, text: FONT_NAMES.AVENIR_NEXT_LPRO_REGULAR}
                ],
                errors: {
                    index_response_message_error: null,
                    score_response_message_error: null,
                    recommendation_error: null,
                    redirect_url_error: null,
                },
                initialData: {},
                checkDirty: true
            }
        },
        created() {
            this.getSettings();
        },
        methods: {
            getSettings() {
                this.$store.dispatch('customizeReport/getSettings', this.user.domain).then((response) => {
                    if (response.report_manager_settings) {

                        this.keyForceUpdate ++;

                        this.reportSettings = {
                            ...this.reportSettings,
                            ...response.report_manager_settings,
                        };
                        this.reportSettings.recommendations = response.user_recommendations ? response.user_recommendations : {};
                        this.reportSettings.labelNames = response.user_short_recommendations && response.user_short_recommendations.label_names ? response.user_short_recommendations.label_names : {};
                        this.reportSettings.shortRecommendations = response.user_short_recommendations && response.user_short_recommendations.short_recommendations ? response.user_short_recommendations.short_recommendations : {}
                        this.viewOptions = JSON.parse(response.report_manager_settings.view_options);

                        let options = response.report_manager_settings.view_options;

                        let newOptions = {
                            ...this.defaultSettings.view_options,
                            ...JSON.parse(options)
                        };

                        this.reportSettings.view_options = newOptions;

                        if (this.reportSettings.logo_path && (this.reportSettings.logo_path.startsWith("http://") || this.reportSettings.logo_path.startsWith("https://"))) {
                            this.reportSettings.logo_path = this.reportSettings.logo_path + `?` + Date.now();
                        }
                        if (this.reportSettings.favicon_path && (this.reportSettings.favicon_path.startsWith("http://") || this.reportSettings.favicon_path.startsWith("https://"))) {
                            this.reportSettings.favicon_path = this.reportSettings.favicon_path + `?` + Date.now();
                        }
                    }
                    this.initialData = JSON.parse(JSON.stringify(this.reportSettings));

                    this.dataLoaded = true;
                }).catch((error) => {
                    this.dataLoaded = true;
                    this.showErrorNotifications(error);
                });
			},
            getRecommendations() {
                if (!this.reportSettings.recommendation_sheet_url) {
                    this.reportSettings.custom_language = 0;
				}
               if (this.reportSettings.recommendation_sheet_url && !this.reportSettings.recommendation_sheet_url.includes("docs.google.com/spreadsheets/") && this.reportSettings.recommendation_sheet_url) {
                    this.errors.recommendation_error = 'Invalid Google sheet URL';
                } else {
                    this.errors.recommendation_error = null;
                    if (this.reportSettings.recommendation_sheet_url && this.reportSettings.recommendation_sheet_url.trim() !== '') {
                        this.$store.dispatch('customizeReport/getRecommendations', this.reportSettings.recommendation_sheet_url).then((response) => {

                            this.reportSettings.custom_language = 1;

                            this.reportSettings.recommendations = response.user_recommendations ? response.user_recommendations : {};
                            this.reportSettings.labelNames = response.user_short_recommendations && response.user_short_recommendations.label_names ? response.user_short_recommendations.label_names : {};
                            this.reportSettings.shortRecommendations = response.user_short_recommendations && response.user_short_recommendations.short_recommendations ? response.user_short_recommendations.short_recommendations : {}
                        }).catch((error) => {
                            this.showErrorNotifications(error);
                        });
                    }
                }
            },
            async save(route = null) {
                this.savingProcessing = true;
                await this.validateSettings();
                if (this.valid) {

                    if (!this.reportSettings.recommendation_sheet_url) {
                        this.reportSettings.custom_language = 0;
                    } else {
                        this.reportSettings.custom_language = 1;
                    }

                    let sendData = JSON.parse(JSON.stringify(this.reportSettings));

                    sendData.view_options = JSON.stringify({
						...this.viewOptions,
                        ...this.reportSettings.view_options,
                    });
                    sendData.logo_path = !sendData.logo_path ? sendData.logo_path : sendData.logo_path.split("?")[0];
                    sendData.favicon_path = !sendData.favicon_path ? sendData.favicon_path : sendData.favicon_path.split("?")[0];

                    delete sendData.labelNames;
                    delete sendData.shortRecommendations;
                    delete sendData.recommendations;
                    // delete sendData.viewOptionFields;

                    this.$store.dispatch('customizeReport/setSettings', sendData)
                        .then(() => {
                            this.checkDirty = false;
                            if (route) {
                                var that = this;
                                setTimeout(function () {
                                    that.$router.push(route).catch(() => {
                                    });
                                }, 500);

                            }
                            this.showNotifications({message: 'Format Report successfully saved', type: 'success'});
                            this.savingProcessing = false;

                        }).catch((error) => {
                        this.savingProcessing = false;
                        this.showErrorNotifications(error)
                    });
                } else {
                    this.openAllAccordions();
                    this.savingProcessing = false;
                }

            },
            openAllAccordions() {
                this.$root.$emit('bv::open::collapse', 'accordion-overview')
            },
            openResetModal() {
                this.$bvModal.show('reset-to-defaults')
            },
            resetAndSave() {
                this.$store.dispatch('customizeReport/resetSettings')
                    .then(() => {
                        this.getSettings();
                        this.showNotifications({message: 'Format Report successfully saved', type: 'success'});
                    }).catch((error) => {
                    this.showErrorNotifications(error)
                });

			},
            async validateSettings() {
                this.valid = true;
                this.errors.score_response_message_error
                    = this.errors.index_response_message_error
                    = this.errors.recommendation_error
                    = this.errors.redirect_url_error = null;

                if (this.showFirstPageOnly) {
                    await validate(this.reportSettings.score_response_message, 'required').then(result => {
                        if (!result.valid) {
                            this.errors.score_response_message_error = 'Message is required';
                            this.valid = false;
                        }
                    });
                } else if (this.doNotShowReport) {
                    await validate(this.reportSettings.index_response_message, 'required').then(result => {
                        if (!result.valid) {
                            this.errors.index_response_message_error = 'Message is required';
                            this.valid = false;
                        }
                    });
                }
                if (this.reportSettings.recommendation_sheet_url && !this.reportSettings.recommendation_sheet_url.includes("docs.google.com/spreadsheets/") && this.reportSettings.recommendation_sheet_url) {
                    this.errors.recommendation_error = 'Invalid Google sheet URL';
                    this.valid = false;
                }


                if (this.reportSettings.redirect_after_downlaod && this.reportSettings.redirect_afterdownload_url && !this.isUrlValid(this.reportSettings.redirect_afterdownload_url)) {
                    this.errors.redirect_url_error = 'Invalid URL';
                    this.valid = false;
                }
            },
            isUrlValid(url) {
                let res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
                return res !== null;
            },

            saveAndRedirect() {
                this.save(this.next)
            },
            redirect() {
                this.checkDirty = false;
                this.$router.push(this.next);
            },
            focusOnError() {
                this.$bvModal.hide('not-filled-mandatory-changes');
                document.querySelector('[id^="input-live-feedback-"]').parentNode.querySelector('input').scrollIntoView(false);
            },

            isDirty() {

                return !(_.isEqual(
                    _.omit(this.initialData, ['recommendations', 'labelNames', 'shortRecommendations']),
                    _.omit(this.reportSettings, ['recommendations', 'labelNames', 'shortRecommendations'])));
            },
        },
        computed: {
            showReport() {
                return this.reportSettings.view_report === VIEW_REPORT.FULL_REPORT;
            },
            showFirstPageOnly() {
                return this.reportSettings.view_report === VIEW_REPORT.FIRST_PAGE_ONLY;
            },
            doNotShowReport() {
                return this.reportSettings.view_report === VIEW_REPORT.DONT_DISPLAY;
            },
            ...mapGetters({
                user: "auth/getUser"
            })
        },
        beforeRouteLeave(to, from, next) {
            if (this.isDirty() && this.checkDirty && this.dataLoaded) {
                this.next = to;
                this.validateSettings().then(() => {
                    if (this.valid) {
                        this.$bvModal.show('not-saved-changes')
                    } else {
                        this.$bvModal.show('not-filled-mandatory-changes')
                    }
                });

            } else {
                next();
            }
        },
    }
</script>

<style lang="scss">
	@import "../../../../../shared/assets/styles/blocks/setup-audit-form";
</style>



